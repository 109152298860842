let fashionWeeksData = [
  "纽约",
  "伦敦",
  "米兰",
  "巴黎",
  "东京",
  "韩国",
  "上海",
  "无",
].map((e) => ({
  label: e,
  value: e,
}));

let stylesData = [
  "简约",
  "通勤",
  "OL",
  "中性",
  "欧美",
  "英伦",
  "自然",
  "文艺",
  "田园",
  "少女",
  "淑女",
  "学院",
  "洛莉塔",
  "民族",
  "朋克",
  "街头",
  "运动",
  "科技",
].map((e) => ({
  label: e,
  value: e,
}));

let onlinePlatformsData = [
  "天猫/淘宝",
  "小红书",
  "Amazon",
  "Farfech",
  "无",
].map((e) => ({
  label: e,
  value: e,
}));

let offlinePlaformsData = [
  "GALLERIES LAFAYETTE",
  "SAKS FIFTH AVENUE",
  "BARNEY’S NEW YORK",
  "HARRODS",
  "SELFRIDGES&CO",
  "LANE CRAWFORD",
  "无",
].map((e) => ({
  label: e,
  value: e,
}));

// 校验规则
let rulesMap = {
  email: [
    {
      required: true,
      message: "请输入邮箱",
      validateTrigger: "onSubmit",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入手机号",
      validateTrigger: "onSubmit",
    },
  ],
  wechat: [
    {
      required: true,
      message: "微信",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  files: [
    {
      validator(rule, value, callback) {
        if (!value) {
          callback("请上传附件");
        }
        if (value) {
          if (value.length === 0) {
            callback("请上传附件");
          }
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  sex: [{ required: true, message: "请选择性别" }],
  country: [{ required: true, message: "请选择国籍" }],
  age: [
    {
      required: true,
      message: "请输入年龄",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  school: [
    {
      required: true,
      message: "请输入毕业学校",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  PRurl: [
    {
      validator(rule, value, callback) {
        if (!value) {
          callback("请上传设计师PR照片");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  experience: [
    {
      validator(rule, value, callback) {
        if (!value) {
          callback("请填写时尚从业经历");
        }
        if (value && value.length > 1000) {
          callback("字数不能超过1000字");
        }
        callback();
      },
      validateTrigger: "onChange",
    },
  ],
  introduction: [
    {
      required: true,
      message: "请填写品牌介绍",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        } else if (value && value.length > 200) {
          callback("字数不能超过200字");
        }
        callback();
      },
      validateTrigger: "onChange",
    },
  ],
  price: [
    {
      validator(rule, value, callback) {
        if (!Array.isArray(value)) {
          callback("请输入价格区间");
        } else {
          if (
            value[0] === undefined ||
            value[0] === "" ||
            value[1] === undefined ||
            value[1] === ""
          ) {
            callback("请输入价格区间");
          }
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  seasonCount: [
    {
      required: true,
      message: "请填写发售季数",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  styles: [
    {
      validator(rule, value, callback) {
        if (!Array.isArray(value)) {
          callback("请选择风格标签");
        } else {
          if (value[0] === undefined) {
            callback("请选择风格标签");
          }
          value.forEach((item) => {
            if (item.value === "") {
              callback("请填写其他的值");
            }
          });
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  onlinePlatforms: [
    {
      validator(rule, value, callback) {
        if (!Array.isArray(value)) {
          callback("请选择线上销售渠道");
        } else {
          if (value[0] === undefined) {
            callback("请选择线上销售渠道");
          }
          value.forEach((item) => {
            if (item.value === "") {
              callback("请填写其他的值");
            }
          });
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  directShopCount: [
    {
      required: true,
      message: "请填写直营店数量",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  buyerShopCount: [
    {
      required: true,
      message: "请填写买手店数量",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  offlinePlaforms: [
    {
      validator(rule, value, callback) {
        if (!Array.isArray(value)) {
          callback("请选择线下发售渠道");
        } else {
          if (value[0] === undefined) {
            callback("请选择线下发售渠道");
          }
          value.forEach((item) => {
            if (item.value === "") {
              callback("请填写其他的值");
            }
          });
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  world: [{ required: true, message: "请选择国家" }],
  province: [{ required: true, message: "请选择省/州" }],
  city: [{ required: true, message: "请选择城市" }],
  address: [
    {
      required: true,
      message: "请填写地址",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  zipCode: [
    {
      required: true,
      message: "请填写邮编",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  landusername: [
    {
      required: true,
      message: "请填写收件人",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  landPhone: [
    {
      required: true,
      message: "请填写电话",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  bususername: [
    {
      required: true,
      message: "请填写联系人姓名",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
          callback("请输入非空信息");
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
  busphone: [
    {
      required: true,
      message: "请填写手机号",
      validateTrigger: "onSubmit",
    },
    {
      validator(rule, value, callback) {
        if (value) {
          if (!/^[\s\S]*.*[^\s][\s\S]*$/.test(value)) {
            callback("请输入非空信息");
          } else if (!/^1[3-9]\d{9}$/.test(value)) {
            callback("请填写有效的手机号");
          }
        }
        callback();
      },
      validateTrigger: "onSubmit",
    },
  ],
};

export {
  stylesData,
  onlinePlatformsData,
  fashionWeeksData,
  offlinePlaformsData,
  rulesMap,
};
