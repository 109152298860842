import React from "react";
import { genErrorBorderColor } from "../utils";
import "./index.css";

export default function Input({ value = "", meta, ...props }) {
  return (
    <input
      className="icy-rc-input"
      value={value}
      style={genErrorBorderColor(meta)}
      {...props}
    />
  );
}
