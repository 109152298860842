import React, { useState, useEffect } from "react";
import { navigateTo } from "gatsby";
import Form from "rc-field-form";
import Message from "rc-message";
import queryString from "query-string";
import ErrorField from "../../../components/error-field";
import Select from "../../../components/select";
import Input from "../../../components/input";
import Textarea from "../../../components/input/textarea";
import Range from "../../../components/@common/input/range";
import CheckboxGroup from "../../../components/@common/checkbox-group";
import Upload from "../../../components/upload";
import UploadGroup from "../../../components/upload/group";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import world from "../../../meta/world";
import { chinaProvince } from "../../../meta/chinaProvince.js";
import { chinaCity } from "../../../meta/chinaCity.js";
import {
  stylesData,
  onlinePlatformsData,
  fashionWeeksData,
  offlinePlaformsData,
  rulesMap,
} from "../../../meta/fullinfoData.js";
import axios from "../../../utils/request";
import style from "./fullInfo.module.css";

function isNulllike(val) {
  return !val && typeof val !== "number";
}

function genCheckedValue(checked, options) {
  const d = options.map((e) => e.value);
  const vals = checked
    .filter((e) => d.includes(e))
    .map((item) => ({
      label: item,
      value: item,
    }));
  const othersVal = checked.find((e) => !d.includes(e));
  if (othersVal) {
    vals.push({
      label: "其他",
      value: othersVal,
    });
  }

  return vals;
}

export default ({ location }) => {
  const [form] = Form.useForm();
  const parse = location.search ? queryString.parse(location.search) : 0;
  const [profile, setProfile] = useState({});
  const [userinfo, setUserInfo] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [isChina, setIsChina] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isJointInfo, setIsJointInfo] = useState(null);
  //  表单结构
  function saveFormData(values) {
    return {
      userId: userinfo?.userId || "",
      baseInfo: {
        sex: values?.sex || 0,
        country: values?.country || "",
        age: !isNulllike(values?.age) ? parseInt(values?.age) : -1,
        school: values?.school || "",
        PRurl: [values?.PRurl] || [],
        logo: [values?.logo] || [],
        award: values?.award || "",
        experience: values?.experience || "",
        fashionWeeks: values?.fashionWeeks?.map((item) => item.value) || [],
      },
      brandInfo: {
        introduction: values?.introduction || "",
        priceMin: !isNulllike(values?.price[0])
          ? parseFloat(values.price[0])
          : -1,
        priceMax: !isNulllike(values?.price[1])
          ? parseFloat(values.price[1])
          : -1,
        seasonCount: !isNulllike(values?.seasonCount)
          ? parseInt(values?.seasonCount)
          : -1,
        styles: values?.styles?.map((item) => item.value) || [],
        urls: [
          {
            type: "offical",
            value: values?.offical || "",
          },
          {
            type: "wechat",
            value: values?.wechat1 || "",
          },
          {
            type: "weibo",
            value: values?.weibo || "",
          },
          {
            type: "facebook",
            value: values?.facebook || "",
          },
          {
            type: "instagram",
            value: values?.instagram || "",
          },
        ],
        onlinePlatforms:
          values?.onlinePlatforms?.map((item) => item.value) || [],
        directShopCount: !isNulllike(values?.directShopCount)
          ? parseInt(values?.directShopCount)
          : -1,
        buyerShopCount: !isNulllike(values?.buyerShopCount)
          ? parseInt(values?.buyerShopCount)
          : -1,
        offlinePlaforms:
          values?.offlinePlaforms?.map((item) => item.value) || [],
      },
      workAddr: {
        country: values?.world || "",
        province: values?.province || "",
        city: values?.city || "",
        address: values?.address || "",
        zipCode: values?.zipCode || "",
        username: values?.landusername || "",
        phone: values?.landphone || "",
      },
      businessContact: {
        username: values?.bususername || "",
        phone: values?.busphone || "",
      },
    };
  }
  // 城市数据渲染
  function handleState(e) {
    if (e === "中国") {
      setIsChina(true);
      const s = chinaProvince.map((item) => {
        return {
          label: item.name,
          value: item.name,
          id: item.id,
        };
      });
      setState(s);
    } else {
      setIsChina(false);
    }
  }

  function handleCity(e) {
    const c = [];
    const chinaId = chinaProvince.find((item) => item.name === e);
    chinaCity[chinaId.id].map((item) => {
      c.push({
        label: item.name,
        value: item.name,
      });
    });
    setCity(c);
  }

  async function handleSave() {
    const values = form.getFieldsValue();
    const dataForm = saveFormData(values);
    const result = {};

    Object.keys(dataForm).forEach((item) => {
      if (item === "userId") {
        result[item] = dataForm[item];
      } else {
        result[item] = {};
      }
      Object.entries(dataForm[item]).forEach((arr) => {
        if (arr[1] !== "") {
          result[item][arr[0]] = dataForm[item][arr[0]];
        }
      });
    });
    // console.log(result);
    const { data } = await axios({
      method: "post",
      url: "/designer/profile",
      data: result,
    });
    // console.log(data);

    if (data.success) {
      Message.success({
        content: "保存成功",
      });
    } else {
      Message.warning({
        content: data.msg,
      });
    }
  }

  useEffect(() => {
    async function getDetail() {
      const { data } = await axios({
        method: "get",
        url: "/designer/profile",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });

      // console.log(data);
      if (data) {
        setProfile(data);
        // console.warn(data.baseInfo);
        form.setFieldsValue({
          fashionWeeks: genCheckedValue(
            data.baseInfo?.fashionWeeks,
            fashionWeeksData
          ),
          styles: genCheckedValue(data.brandInfo?.styles, stylesData),
          onlinePlatforms: genCheckedValue(
            data.brandInfo?.onlinePlatforms,
            onlinePlatformsData
          ),
          offlinePlaforms: genCheckedValue(
            data.brandInfo?.offlinePlaforms,
            offlinePlaformsData
          ),
          price: [
            data?.brandInfo?.priceMin === -1 ? "" : data?.brandInfo?.priceMin,
            data?.brandInfo?.priceMax === -1 ? "" : data?.brandInfo?.priceMax,
          ],
        });

        if (data?.workAddr?.country === "中国") {
          setIsChina(true);
          const s = chinaProvince.map((item) => {
            return {
              label: item.name,
              value: item.name,
              id: item.id,
            };
          });
          setState(s);
        } else {
          setIsChina(false);
        }
      }
    }
    async function getUserinfo() {
      const { data } = await axios({
        method: "get",
        url: "/designer/userinfo",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      if (data) setUserInfo(data);
    }
    getUserinfo();
    getDetail();

    const c = [];
    world.map((e) => {
      c.push({
        label: e.name,
        value: e.name,
      });
    });
    setCountry(c);
    setIsJointInfo(window.localStorage.getItem("jointInfo"));
  }, []);

  useEffect(() => {
    setIsEdit(Boolean(parse.edit));
  }, [parse]);

  return (
    <div>
      <Cursor></Cursor>
      <PublisherHeader
        pathName={location.pathname}
        parseFlag={parse}
      ></PublisherHeader>
      <div className={style.infoWrap}>
        <h2>
          ICY X {isJointInfo ? isJointInfo : userinfo?.jointInfo}
          {parse.edit ? (
            " · 资料编辑"
          ) : (
            <span>（请填写完整设计师及品牌信息，并确保填写信息真实有效）</span>
          )}
        </h2>
        <div style={{ display: isEdit ? "block" : "none" }}>
          <h4>账户信息</h4>
          <div className={style.rowWrap}>
            <div className={`${style.notChange} ${style.colWrap}`}>
              <div className={style.readonlyWrap}>
                <label htmlFor="">设计师名</label>
                {userinfo?.username}
              </div>
            </div>
            <div className={`${style.notChange} ${style.colWrap}`}>
              <div className={style.readonlyWrap}>
                <label htmlFor="">品牌名</label>
                {userinfo?.brand}
              </div>
            </div>
          </div>
        </div>
        <Form
          form={form}
          onFinish={async (values) => {
            // console.log("Finish:", values);
            setLoading(true);
            const account = {
              username: userinfo?.username,
              brand: userinfo?.brand,
              email: values.email,
              phone: values.phone,
              wechat: values.wechat,
              introduction: values.introduction1,
              files: values.files,
            };

            const formData = saveFormData(values);
            formData.baseInfo.onlySave = 1;
            let methodWay;
            let finalData;
            if (parse.edit) {
              methodWay = "put";
              finalData = Object.assign(formData, account);
            } else {
              methodWay = "post";
              finalData = formData;
            }
            try {
              const { data } = await axios({
                method: methodWay,
                url: "/designer/profile",
                data: finalData,
              });

              if (data.success) {
                window.localStorage.removeItem("jointInfo");
                navigateTo("/designerPress/designerIndex");
              } else {
                Message.warning({
                  content: data.msg,
                });
              }
            } catch (err) {
              console.error(err);
            }
            setLoading(false);
          }}
          onFinishFailed={(error) => {
            console.log(error);
            const [first] = error.errorFields;
            if (Array.isArray(first?.name)) {
              const [firstname] = first?.name;
              const [node] = document.getElementsByName(firstname);
              if (node) {
                node.scrollIntoView({
                  block: "center",
                  behavior: "smooth",
                });
              }
            }
          }}
        >
          <div style={isEdit ? { display: "block" } : { display: "none" }}>
            <div className={style.rowWrap}>
              <div className={style.colWrap}>
                <ErrorField
                  rules={[
                    {
                      required: isEdit,
                      message: "请输入邮箱",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="email"
                >
                  <Input
                    label="邮箱"
                    name="email"
                    defaultValue={userinfo?.email}
                  />
                </ErrorField>
              </div>
              <div className={style.colWrap}>
                <ErrorField
                  rules={[
                    {
                      required: isEdit,
                      message: "请输入手机号",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="phone"
                >
                  <Input
                    label="手机号"
                    name="phone"
                    defaultValue={userinfo?.phone}
                  />
                </ErrorField>
              </div>
            </div>

            <div className={style.rowWrap}>
              <div className={style.colWrap}>
                <ErrorField
                  rules={[
                    {
                      required: isEdit,
                      message: "请输入微信号",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="wechat"
                >
                  <Input
                    label="微信"
                    name="wechat"
                    defaultValue={userinfo?.wechat}
                  />
                </ErrorField>
              </div>
            </div>

            <div className={style.rowWrap}>
              <div className={style.colWrap}>
                <ErrorField
                  label="设计师简介"
                  labelNewline
                  labelColor="#6D7278"
                  rules={[
                    {
                      required: isEdit,
                      message: "请输入设计师简介",
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="introduction1"
                >
                  <Textarea
                    defaultValue={userinfo?.introduction}
                    name="introduction1"
                  />
                </ErrorField>
              </div>
            </div>

            <div className={style.rowWrap}>
              <div className={style.colWrap}>
                <ErrorField
                  label="附件"
                  labelColor="#6D7278"
                  labelNewline
                  rules={[
                    {
                      validator(rule, value, callback) {
                        if (isEdit) {
                          if (!value) {
                            callback("请上传附件");
                          }
                          if (value) {
                            if (value.length === 0) {
                              callback("请上传附件");
                            }
                          }
                        }
                        callback();
                      },
                      validateTrigger: "onSubmit",
                    },
                  ]}
                  name="files"
                >
                  <UploadGroup name="files" defaultValue={userinfo?.files} />
                </ErrorField>
              </div>
            </div>
          </div>

          <h4>设计师基本信息</h4>
          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField
                label="*性别"
                rules={rulesMap.sex}
                name="sex"
                labelUnderline
              >
                <Select
                  name="sex"
                  defaultValue={profile?.baseInfo?.sex}
                  options={[
                    {
                      label: "男",
                      value: 1,
                    },
                    {
                      label: "女",
                      value: 2,
                    },
                  ]}
                />
              </ErrorField>
            </div>

            <div className={style.colWrap}>
              <ErrorField
                label="*国籍"
                rules={rulesMap.country}
                name="country"
                labelUnderline
              >
                <Select
                  defaultValue={profile?.baseInfo?.country}
                  // placeholder="请输入"
                  label="标签"
                  showSearch
                  notFoundContent="暂无数据"
                  options={country}
                  name="country"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.age} name="age">
                <Input
                  label="*年龄"
                  defaultValue={
                    profile?.baseInfo?.age === -1
                      ? ""
                      : profile?.baseInfo?.age.toString()
                  }
                  name="age"
                />
              </ErrorField>
            </div>

            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.school} name="school">
                <Input
                  label="*毕业学校"
                  defaultValue={profile?.baseInfo?.school}
                  name="school"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField
                label="*设计师PR照片"
                labelNewline
                rules={rulesMap.PRurl}
                name="PRurl"
              >
                <Upload
                  defaultValue={profile?.baseInfo?.PRurl}
                  accept="image/*"
                  name="PRurl"
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField label="品牌logo" labelNewline name="logo">
                <Upload
                  accept="image/*"
                  defaultValue={profile?.baseInfo?.logo}
                  name="logo"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField label="获奖情况" labelNewline name="award">
                <Textarea
                  defaultValue={profile?.baseInfo?.award}
                  name="award"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField
                label="*时尚从业经历"
                labelNewline
                name="experience"
                rules={rulesMap.experience}
              >
                <Textarea
                  defaultValue={profile?.baseInfo?.experience}
                  name="experience"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <div className={style.checkboxBottom}>
                <ErrorField label="时装周经历" labelNewline name="fashionWeeks">
                  <CheckboxGroup options={fashionWeeksData} />
                </ErrorField>
              </div>
            </div>
          </div>

          <h4>主线品牌</h4>
          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField
                label="*品牌介绍（200字内）"
                labelNewline
                labelColor="#6D7278"
                rules={rulesMap.introduction}
                name="introduction"
              >
                <Textarea
                  defaultValue={profile?.brandInfo?.introduction}
                  name="introduction"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap} style={{ whiteSpace: "nowrap" }}>
              <ErrorField
                label="*价格区间"
                rules={rulesMap.price}
                labelUnderline
                name="price"
              >
                <Range name="price" />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.seasonCount} name="seasonCount">
                <Input
                  label="*发售季数"
                  defaultValue={
                    profile?.brandInfo?.seasonCount === -1
                      ? ""
                      : profile?.brandInfo?.seasonCount?.toString()
                  }
                  name="seasonCount"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <div className={style.checkboxBottom}>
                <ErrorField
                  label="*风格标签（可多选）"
                  labelNewline
                  rules={rulesMap.styles}
                  name="styles"
                >
                  <CheckboxGroup options={stylesData} name="styles" />
                </ErrorField>
              </div>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField name="offical">
                <Input
                  label="官方网站"
                  defaultValue={
                    profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "offical"
                    )[0]?.value || ""
                  }
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField name="wechat1">
                <Input
                  label="品牌公众号"
                  defaultValue={
                    profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "wechat"
                    )[0]?.value || ""
                  }
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField name="weibo">
                <Input
                  label="微博"
                  defaultValue={
                    profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "weibo"
                    )[0]?.value || ""
                  }
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField name="facebook">
                <Input
                  label="Facebook"
                  defaultValue={
                    profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "facebook"
                    )[0]?.value || ""
                  }
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField name="instagram">
                <Input
                  label="Instagram"
                  defaultValue={
                    profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "instagram"
                    )[0]?.value || ""
                  }
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <div className={style.checkboxBottom}>
                <ErrorField
                  label="*线上销售渠道"
                  labelNewline
                  rules={rulesMap.onlinePlatforms}
                  name="onlinePlatforms"
                >
                  <CheckboxGroup
                    options={onlinePlatformsData}
                    name="onlinePlatforms"
                  />
                </ErrorField>
              </div>
            </div>
          </div>

          <h5>*线下发售渠道</h5>
          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField
                rules={rulesMap.directShopCount}
                name="directShopCount"
              >
                <Input
                  label="直营店数量"
                  defaultValue={
                    profile?.brandInfo?.directShopCount === -1
                      ? ""
                      : profile?.brandInfo?.directShopCount?.toString()
                  }
                  name="directShopCount"
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField
                rules={rulesMap.buyerShopCount}
                name="buyerShopCount"
              >
                <Input
                  label="买手店数量"
                  defaultValue={
                    profile?.brandInfo?.buyerShopCount === -1
                      ? ""
                      : profile?.brandInfo?.buyerShopCount?.toString()
                  }
                  name="buyerShopCount"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={`${style.colWrap} ${style.checkboxBottom}`}>
              <ErrorField
                labelNewline
                rules={rulesMap.offlinePlaforms}
                name="offlinePlaforms"
              >
                <CheckboxGroup
                  options={offlinePlaformsData}
                  name="offlinePlaforms"
                />
              </ErrorField>
            </div>
          </div>

          <h4>联系方式</h4>
          <h5>*办公地址</h5>
          <div className={style.rowWrap}>
            <div className={`${style.colWrap} ${style.workSelect} `}>
              <ErrorField
                rules={rulesMap.world}
                name="world"
                labelUnderline
                label="国家"
              >
                <Select
                  defaultValue={profile?.workAddr?.country}
                  notFoundContent="暂无数据"
                  options={country}
                  name="world"
                  fn={handleState}
                />
              </ErrorField>
            </div>
            {isChina && (
              <>
                <div className={`${style.colWrap} ${style.workSelect}`}>
                  <ErrorField
                    rules={rulesMap.province}
                    name="province"
                    labelUnderline
                    label="省/州"
                  >
                    <Select
                      defaultValue={profile?.workAddr?.province}
                      notFoundContent="暂无数据"
                      options={state}
                      fn={handleCity}
                      name="province"
                    />
                  </ErrorField>
                </div>
                <div className={`${style.colWrap} ${style.workSelect}`}>
                  <ErrorField
                    rules={rulesMap.city}
                    name="city"
                    labelUnderline
                    label="城市"
                  >
                    <Select
                      defaultValue={profile?.workAddr?.city}
                      notFoundContent="暂无数据"
                      options={city}
                      name="city"
                    />
                  </ErrorField>
                </div>
              </>
            )}
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.address} name="address">
                <Input
                  label="地址"
                  defaultValue={profile?.workAddr?.address}
                  name="address"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.zipCode} name="zipCode">
                <Input
                  label="邮编"
                  defaultValue={profile?.workAddr?.zipCode}
                  name="zipCode"
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.landusername} name="landusername">
                <Input
                  label="收件人"
                  defaultValue={profile?.workAddr?.username}
                  name="landusername"
                />
              </ErrorField>
            </div>
          </div>

          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.landPhone} name="landphone">
                <Input
                  label="电话"
                  defaultValue={profile?.workAddr?.phone}
                  name="landphone"
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}></div>
          </div>

          <h5>*商务联系人</h5>
          <div className={style.rowWrap}>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.bususername} name="bususername">
                <Input
                  label="联系人姓名"
                  defaultValue={profile?.businessContact?.username}
                  name="bususername"
                />
              </ErrorField>
            </div>
            <div className={style.colWrap}>
              <ErrorField rules={rulesMap.busphone} name="busphone">
                <Input
                  label="手机号"
                  defaultValue={profile?.businessContact?.phone}
                  name="busphone"
                />
              </ErrorField>
            </div>
          </div>
          <div className={style.buttonWrap}>
            {!isEdit && (
              <button
                type="button"
                onClick={handleSave}
                className={`${style.buttonStyle} ${loading ? style.grey : ""} ${
                  !isEdit ? style.shortWidth : ""
                }`}
                style={{ background: "#fff", color: "#000" }}
                disabled={loading}
              >
                {loading && (
                  <img
                    width="640"
                    height="360"
                    src={require("../../../img/icon_loading.png")}
                    alt="icy加载"
                  />
                )}
                仅保存
              </button>
            )}
            <button
              className={`${style.buttonStyle} ${loading ? style.grey : ""} ${
                !isEdit ? style.shortWidth : ""
              }`}
              disabled={loading}
            >
              {loading && (
                <img
                  width="640"
                  height="360"
                  src={require("../../../img/icon_loading.png")}
                  alt="icy加载"
                />
              )}
              {parse.edit ? "保存" : "提交"}
            </button>
          </div>
        </Form>
      </div>
      <div className={style.blackBottom}></div>
    </div>
  );
};
