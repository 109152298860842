import React, { useState, useEffect } from "react";
import RcCheckbox from "rc-checkbox";
import BaseInput from "../input";
import "./index.less";

export default function CheckboxGroup({
  value = [],
  options = [],
  onChange,
  othersLabel = "其他",
  ...props
}) {
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    if (Array.isArray(value)) {
      const c = value.find((e) => e.label === othersLabel);
      if (c) setInputVal(c.value);
    }
  }, [value]);

  function handleCheckedChange(item) {
    return ({ target: t }) => {
      const finded = value.find((e) => e.value === item.value);

      const v =
        !finded && t.checked
          ? [...value, item]
          : (value || []).filter((e) => e.value !== item.value);
      onChange(v);
    };
  }

  function handleOtherCheckedChange() {
    const finded = value.find((e) => e.label === othersLabel);
    const v = !finded
      ? [
          ...value,
          {
            label: othersLabel,
            value: inputVal,
          },
        ]
      : (value || []).filter((e) => e.label !== othersLabel);
    onChange(v);

    setInputVal("");
  }

  function handleInputChange(evt) {
    const v = (value || []).filter((e) => e.label !== othersLabel);

    onChange([
      ...v,
      {
        label: othersLabel,
        value: evt.target.value,
      },
    ]);

    setInputVal(evt.target.value);
  }

  return (
    <section>
      {options.map((e) => (
        <label key={e.value.toString()}>
          <RcCheckbox
            checked={
              (value || []).findIndex((item) => item.value === e.value) > -1
            }
            onChange={handleCheckedChange(e)}
          ></RcCheckbox>

          <span style={{ marginRight: "45px" }} className="rc-checkbox-label">
            {e.label}
          </span>
        </label>
      ))}

      <label>
        <RcCheckbox
          checked={value.findIndex((e) => e.label === othersLabel) > -1}
          onChange={handleOtherCheckedChange}
        ></RcCheckbox>

        <span className="rc-checkbox-label">{othersLabel}</span>

        <BaseInput
          style={{ marginLeft: "15px", width: "350px", height: "40px" }}
          value={inputVal}
          onChange={handleInputChange}
        />
      </label>
    </section>
  );
}
