let chinaCity = {
  110000000000: [
    {
      province: "北京市",
      name: "市辖区",
      id: "110100000000",
    },
  ],
  120000000000: [
    {
      province: "天津市",
      name: "市辖区",
      id: "120100000000",
    },
  ],
  130000000000: [
    {
      province: "河北省",
      name: "石家庄市",
      id: "130100000000",
    },
    {
      province: "河北省",
      name: "唐山市",
      id: "130200000000",
    },
    {
      province: "河北省",
      name: "秦皇岛市",
      id: "130300000000",
    },
    {
      province: "河北省",
      name: "邯郸市",
      id: "130400000000",
    },
    {
      province: "河北省",
      name: "邢台市",
      id: "130500000000",
    },
    {
      province: "河北省",
      name: "保定市",
      id: "130600000000",
    },
    {
      province: "河北省",
      name: "张家口市",
      id: "130700000000",
    },
    {
      province: "河北省",
      name: "承德市",
      id: "130800000000",
    },
    {
      province: "河北省",
      name: "沧州市",
      id: "130900000000",
    },
    {
      province: "河北省",
      name: "廊坊市",
      id: "131000000000",
    },
    {
      province: "河北省",
      name: "衡水市",
      id: "131100000000",
    },
  ],
  140000000000: [
    {
      province: "山西省",
      name: "太原市",
      id: "140100000000",
    },
    {
      province: "山西省",
      name: "大同市",
      id: "140200000000",
    },
    {
      province: "山西省",
      name: "阳泉市",
      id: "140300000000",
    },
    {
      province: "山西省",
      name: "长治市",
      id: "140400000000",
    },
    {
      province: "山西省",
      name: "晋城市",
      id: "140500000000",
    },
    {
      province: "山西省",
      name: "朔州市",
      id: "140600000000",
    },
    {
      province: "山西省",
      name: "晋中市",
      id: "140700000000",
    },
    {
      province: "山西省",
      name: "运城市",
      id: "140800000000",
    },
    {
      province: "山西省",
      name: "忻州市",
      id: "140900000000",
    },
    {
      province: "山西省",
      name: "临汾市",
      id: "141000000000",
    },
    {
      province: "山西省",
      name: "吕梁市",
      id: "141100000000",
    },
  ],
  150000000000: [
    {
      province: "内蒙古自治区",
      name: "呼和浩特市",
      id: "150100000000",
    },
    {
      province: "内蒙古自治区",
      name: "包头市",
      id: "150200000000",
    },
    {
      province: "内蒙古自治区",
      name: "乌海市",
      id: "150300000000",
    },
    {
      province: "内蒙古自治区",
      name: "赤峰市",
      id: "150400000000",
    },
    {
      province: "内蒙古自治区",
      name: "通辽市",
      id: "150500000000",
    },
    {
      province: "内蒙古自治区",
      name: "鄂尔多斯市",
      id: "150600000000",
    },
    {
      province: "内蒙古自治区",
      name: "呼伦贝尔市",
      id: "150700000000",
    },
    {
      province: "内蒙古自治区",
      name: "巴彦淖尔市",
      id: "150800000000",
    },
    {
      province: "内蒙古自治区",
      name: "乌兰察布市",
      id: "150900000000",
    },
    {
      province: "内蒙古自治区",
      name: "兴安盟",
      id: "152200000000",
    },
    {
      province: "内蒙古自治区",
      name: "锡林郭勒盟",
      id: "152500000000",
    },
    {
      province: "内蒙古自治区",
      name: "阿拉善盟",
      id: "152900000000",
    },
  ],
  210000000000: [
    {
      province: "辽宁省",
      name: "沈阳市",
      id: "210100000000",
    },
    {
      province: "辽宁省",
      name: "大连市",
      id: "210200000000",
    },
    {
      province: "辽宁省",
      name: "鞍山市",
      id: "210300000000",
    },
    {
      province: "辽宁省",
      name: "抚顺市",
      id: "210400000000",
    },
    {
      province: "辽宁省",
      name: "本溪市",
      id: "210500000000",
    },
    {
      province: "辽宁省",
      name: "丹东市",
      id: "210600000000",
    },
    {
      province: "辽宁省",
      name: "锦州市",
      id: "210700000000",
    },
    {
      province: "辽宁省",
      name: "营口市",
      id: "210800000000",
    },
    {
      province: "辽宁省",
      name: "阜新市",
      id: "210900000000",
    },
    {
      province: "辽宁省",
      name: "辽阳市",
      id: "211000000000",
    },
    {
      province: "辽宁省",
      name: "盘锦市",
      id: "211100000000",
    },
    {
      province: "辽宁省",
      name: "铁岭市",
      id: "211200000000",
    },
    {
      province: "辽宁省",
      name: "朝阳市",
      id: "211300000000",
    },
    {
      province: "辽宁省",
      name: "葫芦岛市",
      id: "211400000000",
    },
  ],
  220000000000: [
    {
      province: "吉林省",
      name: "长春市",
      id: "220100000000",
    },
    {
      province: "吉林省",
      name: "吉林市",
      id: "220200000000",
    },
    {
      province: "吉林省",
      name: "四平市",
      id: "220300000000",
    },
    {
      province: "吉林省",
      name: "辽源市",
      id: "220400000000",
    },
    {
      province: "吉林省",
      name: "通化市",
      id: "220500000000",
    },
    {
      province: "吉林省",
      name: "白山市",
      id: "220600000000",
    },
    {
      province: "吉林省",
      name: "松原市",
      id: "220700000000",
    },
    {
      province: "吉林省",
      name: "白城市",
      id: "220800000000",
    },
    {
      province: "吉林省",
      name: "延边朝鲜族自治州",
      id: "222400000000",
    },
  ],
  230000000000: [
    {
      province: "黑龙江省",
      name: "哈尔滨市",
      id: "230100000000",
    },
    {
      province: "黑龙江省",
      name: "齐齐哈尔市",
      id: "230200000000",
    },
    {
      province: "黑龙江省",
      name: "鸡西市",
      id: "230300000000",
    },
    {
      province: "黑龙江省",
      name: "鹤岗市",
      id: "230400000000",
    },
    {
      province: "黑龙江省",
      name: "双鸭山市",
      id: "230500000000",
    },
    {
      province: "黑龙江省",
      name: "大庆市",
      id: "230600000000",
    },
    {
      province: "黑龙江省",
      name: "伊春市",
      id: "230700000000",
    },
    {
      province: "黑龙江省",
      name: "佳木斯市",
      id: "230800000000",
    },
    {
      province: "黑龙江省",
      name: "七台河市",
      id: "230900000000",
    },
    {
      province: "黑龙江省",
      name: "牡丹江市",
      id: "231000000000",
    },
    {
      province: "黑龙江省",
      name: "黑河市",
      id: "231100000000",
    },
    {
      province: "黑龙江省",
      name: "绥化市",
      id: "231200000000",
    },
    {
      province: "黑龙江省",
      name: "大兴安岭地区",
      id: "232700000000",
    },
  ],
  310000000000: [
    {
      province: "上海市",
      name: "市辖区",
      id: "310100000000",
    },
  ],
  320000000000: [
    {
      province: "江苏省",
      name: "南京市",
      id: "320100000000",
    },
    {
      province: "江苏省",
      name: "无锡市",
      id: "320200000000",
    },
    {
      province: "江苏省",
      name: "徐州市",
      id: "320300000000",
    },
    {
      province: "江苏省",
      name: "常州市",
      id: "320400000000",
    },
    {
      province: "江苏省",
      name: "苏州市",
      id: "320500000000",
    },
    {
      province: "江苏省",
      name: "南通市",
      id: "320600000000",
    },
    {
      province: "江苏省",
      name: "连云港市",
      id: "320700000000",
    },
    {
      province: "江苏省",
      name: "淮安市",
      id: "320800000000",
    },
    {
      province: "江苏省",
      name: "盐城市",
      id: "320900000000",
    },
    {
      province: "江苏省",
      name: "扬州市",
      id: "321000000000",
    },
    {
      province: "江苏省",
      name: "镇江市",
      id: "321100000000",
    },
    {
      province: "江苏省",
      name: "泰州市",
      id: "321200000000",
    },
    {
      province: "江苏省",
      name: "宿迁市",
      id: "321300000000",
    },
  ],
  330000000000: [
    {
      province: "浙江省",
      name: "杭州市",
      id: "330100000000",
    },
    {
      province: "浙江省",
      name: "宁波市",
      id: "330200000000",
    },
    {
      province: "浙江省",
      name: "温州市",
      id: "330300000000",
    },
    {
      province: "浙江省",
      name: "嘉兴市",
      id: "330400000000",
    },
    {
      province: "浙江省",
      name: "湖州市",
      id: "330500000000",
    },
    {
      province: "浙江省",
      name: "绍兴市",
      id: "330600000000",
    },
    {
      province: "浙江省",
      name: "金华市",
      id: "330700000000",
    },
    {
      province: "浙江省",
      name: "衢州市",
      id: "330800000000",
    },
    {
      province: "浙江省",
      name: "舟山市",
      id: "330900000000",
    },
    {
      province: "浙江省",
      name: "台州市",
      id: "331000000000",
    },
    {
      province: "浙江省",
      name: "丽水市",
      id: "331100000000",
    },
  ],
  340000000000: [
    {
      province: "安徽省",
      name: "合肥市",
      id: "340100000000",
    },
    {
      province: "安徽省",
      name: "芜湖市",
      id: "340200000000",
    },
    {
      province: "安徽省",
      name: "蚌埠市",
      id: "340300000000",
    },
    {
      province: "安徽省",
      name: "淮南市",
      id: "340400000000",
    },
    {
      province: "安徽省",
      name: "马鞍山市",
      id: "340500000000",
    },
    {
      province: "安徽省",
      name: "淮北市",
      id: "340600000000",
    },
    {
      province: "安徽省",
      name: "铜陵市",
      id: "340700000000",
    },
    {
      province: "安徽省",
      name: "安庆市",
      id: "340800000000",
    },
    {
      province: "安徽省",
      name: "黄山市",
      id: "341000000000",
    },
    {
      province: "安徽省",
      name: "滁州市",
      id: "341100000000",
    },
    {
      province: "安徽省",
      name: "阜阳市",
      id: "341200000000",
    },
    {
      province: "安徽省",
      name: "宿州市",
      id: "341300000000",
    },
    {
      province: "安徽省",
      name: "六安市",
      id: "341500000000",
    },
    {
      province: "安徽省",
      name: "亳州市",
      id: "341600000000",
    },
    {
      province: "安徽省",
      name: "池州市",
      id: "341700000000",
    },
    {
      province: "安徽省",
      name: "宣城市",
      id: "341800000000",
    },
  ],
  350000000000: [
    {
      province: "福建省",
      name: "福州市",
      id: "350100000000",
    },
    {
      province: "福建省",
      name: "厦门市",
      id: "350200000000",
    },
    {
      province: "福建省",
      name: "莆田市",
      id: "350300000000",
    },
    {
      province: "福建省",
      name: "三明市",
      id: "350400000000",
    },
    {
      province: "福建省",
      name: "泉州市",
      id: "350500000000",
    },
    {
      province: "福建省",
      name: "漳州市",
      id: "350600000000",
    },
    {
      province: "福建省",
      name: "南平市",
      id: "350700000000",
    },
    {
      province: "福建省",
      name: "龙岩市",
      id: "350800000000",
    },
    {
      province: "福建省",
      name: "宁德市",
      id: "350900000000",
    },
  ],
  360000000000: [
    {
      province: "江西省",
      name: "南昌市",
      id: "360100000000",
    },
    {
      province: "江西省",
      name: "景德镇市",
      id: "360200000000",
    },
    {
      province: "江西省",
      name: "萍乡市",
      id: "360300000000",
    },
    {
      province: "江西省",
      name: "九江市",
      id: "360400000000",
    },
    {
      province: "江西省",
      name: "新余市",
      id: "360500000000",
    },
    {
      province: "江西省",
      name: "鹰潭市",
      id: "360600000000",
    },
    {
      province: "江西省",
      name: "赣州市",
      id: "360700000000",
    },
    {
      province: "江西省",
      name: "吉安市",
      id: "360800000000",
    },
    {
      province: "江西省",
      name: "宜春市",
      id: "360900000000",
    },
    {
      province: "江西省",
      name: "抚州市",
      id: "361000000000",
    },
    {
      province: "江西省",
      name: "上饶市",
      id: "361100000000",
    },
  ],
  370000000000: [
    {
      province: "山东省",
      name: "济南市",
      id: "370100000000",
    },
    {
      province: "山东省",
      name: "青岛市",
      id: "370200000000",
    },
    {
      province: "山东省",
      name: "淄博市",
      id: "370300000000",
    },
    {
      province: "山东省",
      name: "枣庄市",
      id: "370400000000",
    },
    {
      province: "山东省",
      name: "东营市",
      id: "370500000000",
    },
    {
      province: "山东省",
      name: "烟台市",
      id: "370600000000",
    },
    {
      province: "山东省",
      name: "潍坊市",
      id: "370700000000",
    },
    {
      province: "山东省",
      name: "济宁市",
      id: "370800000000",
    },
    {
      province: "山东省",
      name: "泰安市",
      id: "370900000000",
    },
    {
      province: "山东省",
      name: "威海市",
      id: "371000000000",
    },
    {
      province: "山东省",
      name: "日照市",
      id: "371100000000",
    },
    {
      province: "山东省",
      name: "莱芜市",
      id: "371200000000",
    },
    {
      province: "山东省",
      name: "临沂市",
      id: "371300000000",
    },
    {
      province: "山东省",
      name: "德州市",
      id: "371400000000",
    },
    {
      province: "山东省",
      name: "聊城市",
      id: "371500000000",
    },
    {
      province: "山东省",
      name: "滨州市",
      id: "371600000000",
    },
    {
      province: "山东省",
      name: "菏泽市",
      id: "371700000000",
    },
  ],
  410000000000: [
    {
      province: "河南省",
      name: "郑州市",
      id: "410100000000",
    },
    {
      province: "河南省",
      name: "开封市",
      id: "410200000000",
    },
    {
      province: "河南省",
      name: "洛阳市",
      id: "410300000000",
    },
    {
      province: "河南省",
      name: "平顶山市",
      id: "410400000000",
    },
    {
      province: "河南省",
      name: "安阳市",
      id: "410500000000",
    },
    {
      province: "河南省",
      name: "鹤壁市",
      id: "410600000000",
    },
    {
      province: "河南省",
      name: "新乡市",
      id: "410700000000",
    },
    {
      province: "河南省",
      name: "焦作市",
      id: "410800000000",
    },
    {
      province: "河南省",
      name: "濮阳市",
      id: "410900000000",
    },
    {
      province: "河南省",
      name: "许昌市",
      id: "411000000000",
    },
    {
      province: "河南省",
      name: "漯河市",
      id: "411100000000",
    },
    {
      province: "河南省",
      name: "三门峡市",
      id: "411200000000",
    },
    {
      province: "河南省",
      name: "南阳市",
      id: "411300000000",
    },
    {
      province: "河南省",
      name: "商丘市",
      id: "411400000000",
    },
    {
      province: "河南省",
      name: "信阳市",
      id: "411500000000",
    },
    {
      province: "河南省",
      name: "周口市",
      id: "411600000000",
    },
    {
      province: "河南省",
      name: "驻马店市",
      id: "411700000000",
    },
    {
      province: "河南省",
      name: "省直辖县级行政区划",
      id: "419000000000",
    },
  ],
  420000000000: [
    {
      province: "湖北省",
      name: "武汉市",
      id: "420100000000",
    },
    {
      province: "湖北省",
      name: "黄石市",
      id: "420200000000",
    },
    {
      province: "湖北省",
      name: "十堰市",
      id: "420300000000",
    },
    {
      province: "湖北省",
      name: "宜昌市",
      id: "420500000000",
    },
    {
      province: "湖北省",
      name: "襄阳市",
      id: "420600000000",
    },
    {
      province: "湖北省",
      name: "鄂州市",
      id: "420700000000",
    },
    {
      province: "湖北省",
      name: "荆门市",
      id: "420800000000",
    },
    {
      province: "湖北省",
      name: "孝感市",
      id: "420900000000",
    },
    {
      province: "湖北省",
      name: "荆州市",
      id: "421000000000",
    },
    {
      province: "湖北省",
      name: "黄冈市",
      id: "421100000000",
    },
    {
      province: "湖北省",
      name: "咸宁市",
      id: "421200000000",
    },
    {
      province: "湖北省",
      name: "随州市",
      id: "421300000000",
    },
    {
      province: "湖北省",
      name: "恩施土家族苗族自治州",
      id: "422800000000",
    },
    {
      province: "湖北省",
      name: "省直辖县级行政区划",
      id: "429000000000",
    },
  ],
  430000000000: [
    {
      province: "湖南省",
      name: "长沙市",
      id: "430100000000",
    },
    {
      province: "湖南省",
      name: "株洲市",
      id: "430200000000",
    },
    {
      province: "湖南省",
      name: "湘潭市",
      id: "430300000000",
    },
    {
      province: "湖南省",
      name: "衡阳市",
      id: "430400000000",
    },
    {
      province: "湖南省",
      name: "邵阳市",
      id: "430500000000",
    },
    {
      province: "湖南省",
      name: "岳阳市",
      id: "430600000000",
    },
    {
      province: "湖南省",
      name: "常德市",
      id: "430700000000",
    },
    {
      province: "湖南省",
      name: "张家界市",
      id: "430800000000",
    },
    {
      province: "湖南省",
      name: "益阳市",
      id: "430900000000",
    },
    {
      province: "湖南省",
      name: "郴州市",
      id: "431000000000",
    },
    {
      province: "湖南省",
      name: "永州市",
      id: "431100000000",
    },
    {
      province: "湖南省",
      name: "怀化市",
      id: "431200000000",
    },
    {
      province: "湖南省",
      name: "娄底市",
      id: "431300000000",
    },
    {
      province: "湖南省",
      name: "湘西土家族苗族自治州",
      id: "433100000000",
    },
  ],
  440000000000: [
    {
      province: "广东省",
      name: "广州市",
      id: "440100000000",
    },
    {
      province: "广东省",
      name: "韶关市",
      id: "440200000000",
    },
    {
      province: "广东省",
      name: "深圳市",
      id: "440300000000",
    },
    {
      province: "广东省",
      name: "珠海市",
      id: "440400000000",
    },
    {
      province: "广东省",
      name: "汕头市",
      id: "440500000000",
    },
    {
      province: "广东省",
      name: "佛山市",
      id: "440600000000",
    },
    {
      province: "广东省",
      name: "江门市",
      id: "440700000000",
    },
    {
      province: "广东省",
      name: "湛江市",
      id: "440800000000",
    },
    {
      province: "广东省",
      name: "茂名市",
      id: "440900000000",
    },
    {
      province: "广东省",
      name: "肇庆市",
      id: "441200000000",
    },
    {
      province: "广东省",
      name: "惠州市",
      id: "441300000000",
    },
    {
      province: "广东省",
      name: "梅州市",
      id: "441400000000",
    },
    {
      province: "广东省",
      name: "汕尾市",
      id: "441500000000",
    },
    {
      province: "广东省",
      name: "河源市",
      id: "441600000000",
    },
    {
      province: "广东省",
      name: "阳江市",
      id: "441700000000",
    },
    {
      province: "广东省",
      name: "清远市",
      id: "441800000000",
    },
    {
      province: "广东省",
      name: "东莞市",
      id: "441900000000",
    },
    {
      province: "广东省",
      name: "中山市",
      id: "442000000000",
    },
    {
      province: "广东省",
      name: "潮州市",
      id: "445100000000",
    },
    {
      province: "广东省",
      name: "揭阳市",
      id: "445200000000",
    },
    {
      province: "广东省",
      name: "云浮市",
      id: "445300000000",
    },
  ],
  450000000000: [
    {
      province: "广西壮族自治区",
      name: "南宁市",
      id: "450100000000",
    },
    {
      province: "广西壮族自治区",
      name: "柳州市",
      id: "450200000000",
    },
    {
      province: "广西壮族自治区",
      name: "桂林市",
      id: "450300000000",
    },
    {
      province: "广西壮族自治区",
      name: "梧州市",
      id: "450400000000",
    },
    {
      province: "广西壮族自治区",
      name: "北海市",
      id: "450500000000",
    },
    {
      province: "广西壮族自治区",
      name: "防城港市",
      id: "450600000000",
    },
    {
      province: "广西壮族自治区",
      name: "钦州市",
      id: "450700000000",
    },
    {
      province: "广西壮族自治区",
      name: "贵港市",
      id: "450800000000",
    },
    {
      province: "广西壮族自治区",
      name: "玉林市",
      id: "450900000000",
    },
    {
      province: "广西壮族自治区",
      name: "百色市",
      id: "451000000000",
    },
    {
      province: "广西壮族自治区",
      name: "贺州市",
      id: "451100000000",
    },
    {
      province: "广西壮族自治区",
      name: "河池市",
      id: "451200000000",
    },
    {
      province: "广西壮族自治区",
      name: "来宾市",
      id: "451300000000",
    },
    {
      province: "广西壮族自治区",
      name: "崇左市",
      id: "451400000000",
    },
  ],
  460000000000: [
    {
      province: "海南省",
      name: "海口市",
      id: "460100000000",
    },
    {
      province: "海南省",
      name: "三亚市",
      id: "460200000000",
    },
    {
      province: "海南省",
      name: "三沙市",
      id: "460300000000",
    },
    {
      province: "海南省",
      name: "儋州市",
      id: "460400000000",
    },
    {
      province: "海南省",
      name: "省直辖县级行政区划",
      id: "469000000000",
    },
  ],
  500000000000: [
    {
      province: "重庆市",
      name: "市辖区",
      id: "500100000000",
    },
    {
      province: "重庆市",
      name: "县",
      id: "500200000000",
    },
  ],
  510000000000: [
    {
      province: "四川省",
      name: "成都市",
      id: "510100000000",
    },
    {
      province: "四川省",
      name: "自贡市",
      id: "510300000000",
    },
    {
      province: "四川省",
      name: "攀枝花市",
      id: "510400000000",
    },
    {
      province: "四川省",
      name: "泸州市",
      id: "510500000000",
    },
    {
      province: "四川省",
      name: "德阳市",
      id: "510600000000",
    },
    {
      province: "四川省",
      name: "绵阳市",
      id: "510700000000",
    },
    {
      province: "四川省",
      name: "广元市",
      id: "510800000000",
    },
    {
      province: "四川省",
      name: "遂宁市",
      id: "510900000000",
    },
    {
      province: "四川省",
      name: "内江市",
      id: "511000000000",
    },
    {
      province: "四川省",
      name: "乐山市",
      id: "511100000000",
    },
    {
      province: "四川省",
      name: "南充市",
      id: "511300000000",
    },
    {
      province: "四川省",
      name: "眉山市",
      id: "511400000000",
    },
    {
      province: "四川省",
      name: "宜宾市",
      id: "511500000000",
    },
    {
      province: "四川省",
      name: "广安市",
      id: "511600000000",
    },
    {
      province: "四川省",
      name: "达州市",
      id: "511700000000",
    },
    {
      province: "四川省",
      name: "雅安市",
      id: "511800000000",
    },
    {
      province: "四川省",
      name: "巴中市",
      id: "511900000000",
    },
    {
      province: "四川省",
      name: "资阳市",
      id: "512000000000",
    },
    {
      province: "四川省",
      name: "阿坝藏族羌族自治州",
      id: "513200000000",
    },
    {
      province: "四川省",
      name: "甘孜藏族自治州",
      id: "513300000000",
    },
    {
      province: "四川省",
      name: "凉山彝族自治州",
      id: "513400000000",
    },
  ],
  520000000000: [
    {
      province: "贵州省",
      name: "贵阳市",
      id: "520100000000",
    },
    {
      province: "贵州省",
      name: "六盘水市",
      id: "520200000000",
    },
    {
      province: "贵州省",
      name: "遵义市",
      id: "520300000000",
    },
    {
      province: "贵州省",
      name: "安顺市",
      id: "520400000000",
    },
    {
      province: "贵州省",
      name: "毕节市",
      id: "520500000000",
    },
    {
      province: "贵州省",
      name: "铜仁市",
      id: "520600000000",
    },
    {
      province: "贵州省",
      name: "黔西南布依族苗族自治州",
      id: "522300000000",
    },
    {
      province: "贵州省",
      name: "黔东南苗族侗族自治州",
      id: "522600000000",
    },
    {
      province: "贵州省",
      name: "黔南布依族苗族自治州",
      id: "522700000000",
    },
  ],
  530000000000: [
    {
      province: "云南省",
      name: "昆明市",
      id: "530100000000",
    },
    {
      province: "云南省",
      name: "曲靖市",
      id: "530300000000",
    },
    {
      province: "云南省",
      name: "玉溪市",
      id: "530400000000",
    },
    {
      province: "云南省",
      name: "保山市",
      id: "530500000000",
    },
    {
      province: "云南省",
      name: "昭通市",
      id: "530600000000",
    },
    {
      province: "云南省",
      name: "丽江市",
      id: "530700000000",
    },
    {
      province: "云南省",
      name: "普洱市",
      id: "530800000000",
    },
    {
      province: "云南省",
      name: "临沧市",
      id: "530900000000",
    },
    {
      province: "云南省",
      name: "楚雄彝族自治州",
      id: "532300000000",
    },
    {
      province: "云南省",
      name: "红河哈尼族彝族自治州",
      id: "532500000000",
    },
    {
      province: "云南省",
      name: "文山壮族苗族自治州",
      id: "532600000000",
    },
    {
      province: "云南省",
      name: "西双版纳傣族自治州",
      id: "532800000000",
    },
    {
      province: "云南省",
      name: "大理白族自治州",
      id: "532900000000",
    },
    {
      province: "云南省",
      name: "德宏傣族景颇族自治州",
      id: "533100000000",
    },
    {
      province: "云南省",
      name: "怒江傈僳族自治州",
      id: "533300000000",
    },
    {
      province: "云南省",
      name: "迪庆藏族自治州",
      id: "533400000000",
    },
  ],
  540000000000: [
    {
      province: "西藏自治区",
      name: "拉萨市",
      id: "540100000000",
    },
    {
      province: "西藏自治区",
      name: "日喀则市",
      id: "540200000000",
    },
    {
      province: "西藏自治区",
      name: "昌都市",
      id: "540300000000",
    },
    {
      province: "西藏自治区",
      name: "林芝市",
      id: "540400000000",
    },
    {
      province: "西藏自治区",
      name: "山南市",
      id: "540500000000",
    },
    {
      province: "西藏自治区",
      name: "那曲市",
      id: "540600000000",
    },
    {
      province: "西藏自治区",
      name: "阿里地区",
      id: "542500000000",
    },
  ],
  610000000000: [
    {
      province: "陕西省",
      name: "西安市",
      id: "610100000000",
    },
    {
      province: "陕西省",
      name: "铜川市",
      id: "610200000000",
    },
    {
      province: "陕西省",
      name: "宝鸡市",
      id: "610300000000",
    },
    {
      province: "陕西省",
      name: "咸阳市",
      id: "610400000000",
    },
    {
      province: "陕西省",
      name: "渭南市",
      id: "610500000000",
    },
    {
      province: "陕西省",
      name: "延安市",
      id: "610600000000",
    },
    {
      province: "陕西省",
      name: "汉中市",
      id: "610700000000",
    },
    {
      province: "陕西省",
      name: "榆林市",
      id: "610800000000",
    },
    {
      province: "陕西省",
      name: "安康市",
      id: "610900000000",
    },
    {
      province: "陕西省",
      name: "商洛市",
      id: "611000000000",
    },
  ],
  620000000000: [
    {
      province: "甘肃省",
      name: "兰州市",
      id: "620100000000",
    },
    {
      province: "甘肃省",
      name: "嘉峪关市",
      id: "620200000000",
    },
    {
      province: "甘肃省",
      name: "金昌市",
      id: "620300000000",
    },
    {
      province: "甘肃省",
      name: "白银市",
      id: "620400000000",
    },
    {
      province: "甘肃省",
      name: "天水市",
      id: "620500000000",
    },
    {
      province: "甘肃省",
      name: "武威市",
      id: "620600000000",
    },
    {
      province: "甘肃省",
      name: "张掖市",
      id: "620700000000",
    },
    {
      province: "甘肃省",
      name: "平凉市",
      id: "620800000000",
    },
    {
      province: "甘肃省",
      name: "酒泉市",
      id: "620900000000",
    },
    {
      province: "甘肃省",
      name: "庆阳市",
      id: "621000000000",
    },
    {
      province: "甘肃省",
      name: "定西市",
      id: "621100000000",
    },
    {
      province: "甘肃省",
      name: "陇南市",
      id: "621200000000",
    },
    {
      province: "甘肃省",
      name: "临夏回族自治州",
      id: "622900000000",
    },
    {
      province: "甘肃省",
      name: "甘南藏族自治州",
      id: "623000000000",
    },
  ],
  630000000000: [
    {
      province: "青海省",
      name: "西宁市",
      id: "630100000000",
    },
    {
      province: "青海省",
      name: "海东市",
      id: "630200000000",
    },
    {
      province: "青海省",
      name: "海北藏族自治州",
      id: "632200000000",
    },
    {
      province: "青海省",
      name: "黄南藏族自治州",
      id: "632300000000",
    },
    {
      province: "青海省",
      name: "海南藏族自治州",
      id: "632500000000",
    },
    {
      province: "青海省",
      name: "果洛藏族自治州",
      id: "632600000000",
    },
    {
      province: "青海省",
      name: "玉树藏族自治州",
      id: "632700000000",
    },
    {
      province: "青海省",
      name: "海西蒙古族藏族自治州",
      id: "632800000000",
    },
  ],
  640000000000: [
    {
      province: "宁夏回族自治区",
      name: "银川市",
      id: "640100000000",
    },
    {
      province: "宁夏回族自治区",
      name: "石嘴山市",
      id: "640200000000",
    },
    {
      province: "宁夏回族自治区",
      name: "吴忠市",
      id: "640300000000",
    },
    {
      province: "宁夏回族自治区",
      name: "固原市",
      id: "640400000000",
    },
    {
      province: "宁夏回族自治区",
      name: "中卫市",
      id: "640500000000",
    },
  ],
  650000000000: [
    {
      province: "新疆维吾尔自治区",
      name: "乌鲁木齐市",
      id: "650100000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "克拉玛依市",
      id: "650200000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "吐鲁番市",
      id: "650400000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "哈密市",
      id: "650500000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "昌吉回族自治州",
      id: "652300000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "博尔塔拉蒙古自治州",
      id: "652700000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "巴音郭楞蒙古自治州",
      id: "652800000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "阿克苏地区",
      id: "652900000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "克孜勒苏柯尔克孜自治州",
      id: "653000000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "喀什地区",
      id: "653100000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "和田地区",
      id: "653200000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "伊犁哈萨克自治州",
      id: "654000000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "塔城地区",
      id: "654200000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "阿勒泰地区",
      id: "654300000000",
    },
    {
      province: "新疆维吾尔自治区",
      name: "自治区直辖县级行政区划",
      id: "659000000000",
    },
  ],
};
export { chinaCity };
