import React, { useState, useEffect } from "react";
import BaseInput from "./index";
import { genErrorBorderColor } from "../utils";
import "./index.css";

export default function RangeInput({ value, meta, onChange, ...props }) {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const s = genErrorBorderColor(meta);

  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      setMin(value[0]);
      setMax(value[1]);
    }
  }, [value]);

  function handleSetMin(e) {
    const v = e.target.value;
    setMin(v);
    onChange([v, max]);
  }

  function handleSetMax(e) {
    const v = e.target.value;
    setMax(v);
    onChange([min, v]);
  }

  return (
    <div className="icy-rc-rangeinput">
      <BaseInput value={min} style={s} onChange={handleSetMin} {...props} style={{height:'100%'}} />

      <span className="icy-rc-rangeinput__separator" style={s}>
        ~
      </span>

      <BaseInput value={max} style={s} onChange={handleSetMax} {...props} style={{height:'100%'}} />
    </div>
  );
}
